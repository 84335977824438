<template>
  <v-container class="content">
    <v-row no-gutters>
      <v-col align="center">
        <h1 class="mb-8">Order {{ order.id }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-card-title>Info</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4">
                Created:  {{ new Date(order.createdDate).toLocaleString() }}
              </v-col>
              <v-col cols="4">
                Created By: <retailer-user-badge :id="order.createdBy"></retailer-user-badge>
              </v-col>
              <v-col cols="4">
                Status: {{order.status}}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card elevation="3">
          <v-card-title>Items</v-card-title>
          <v-card-text>
            <order-items v-model="order.orderItems" mode="view"></order-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import axios from "axios";
import OrderItems from "@/components/general/OrderItems.vue";
import RetailerUserBadge from "@/components/retailer/RetailerUserBadge.vue";

export default {
  components: {RetailerUserBadge, OrderItems},
  name: "RetailerOrderView",
  metaInfo: {
    title: "Order View",
  },
  data() {
    return {
      order: {},
      id: null,
      loading: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios.get("/api/retailer/order/" + this.id).then((response) => {
        this.order = response.data;
      });
    },
  },
};
</script>

<style scoped>
</style>
